import * as React from "react";
import { Box, Typography, Link } from "@mui/material";
import bioCollage from "../assets/BioPic.png";

export default function Bio() {
  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100vw",
        minHeight: "100vh",
        display: "flex",
        flexDirection: ["column", "row", "row"],
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: ["90%", "45%", "45%"],
          justifyContent: "center",
          alignItems: "flex-start",
          mt: [8, 9, 10],
          mb: [3, 4, 5],
        }}
      >
        <Typography
          variant="h2"
          sx={{
            color: "#00ffff",
            textAlign: "left",
            fontWeight: "bold",
          }}
        >
          About Me
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#ffffff",
            textAlign: "left",
          }}
        >
          Philadelphia-based video editor and graphic designer that has a
          passion for sports and content creation—recording and editing
          high-quality content for businesses, teams, and clients alike. I have
          experience in all three phases of media creation for the purpose of
          distributing content on social media, private sharing, or website
          display.
          <br />
          <br />
          Contact me for projects and edits at{" "}
          <Link
            underline="hover"
            href="mailto:JonHerbertMedia@gmail.com"
            sx={{ color: "#ffffff" }}
          >
            JonHerbertMedia@gmail.com
          </Link>
          .
        </Typography>
      </Box>
      <Box
        sx={{
          width: ["90%", "50%", "50%"],
          mt: [3, 4, 5],
          mb: [3, 4, 5],
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          id="bioCollage_img"
          src={bioCollage}
          alt="bioCollage"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
          }}
        />
      </Box>
    </Box>
  );
}
