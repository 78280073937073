import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import NavBar from "./NavBar";
import Contact from "./Contact";
import Bio from "./Bio";
import "../styles/App.css";
import logo from "../assets/DesignPFPWhite.png";

export default function Home() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 1000);
  }, []);

  return (
    <div>
      <NavBar />
      <div id="home" className="faded-container">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            id="largeLogo_img"
            src={logo}
            alt="largeLogo"
            style={{
              maxWidth: "50%",
              maxHeight: "50%",
              opacity: loaded ? 1 : 0,
              transition: "opacity 2s",
            }}
          />
        </Box>
      </div>
      <div id="bio">
        <Bio />
      </div>
      <div id="contact">
        <Contact />
      </div>
    </div>
  );
}
