import * as React from "react";
import { AppBar, Box, Toolbar, Link } from "@mui/material";
import logo from "../assets/CompressedLogo.png";

export default function NavBar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" elevation={0} sx={{ background: "none", mt: 1 }}>
        <Toolbar>
          <Box sx={{ display: "flex", flexGrow: 1, ml: 2 }}>
            <Link href="#home">
              <img
                src={logo}
                alt="nav-logo"
                height="48"
                width="auto"
                style={{ cursor: "pointer" }}
              />
            </Link>
          </Box>
          <Link underline="hover" href="#bio" sx={{ mr: 4, color: "#00ffff" }}>
            Bio
          </Link>
          {/* <Link
            underline="hover"
            href="#collections"
            sx={{ mr: 4, color: "#00ffff" }}
          >
            Work
          </Link> */}
          <Link
            underline="hover"
            href="#contact"
            sx={{ mr: 4, color: "#00ffff" }}
          >
            Contact
          </Link>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
